import { Component, Input, OnInit } from '@angular/core';
import {
  LAUNCHER_LOGIN_URL,
  LAUNCHER_MANAGE_USERS_URL,
  LAUNCHER_URL,
  MULTIDYNE_HOME_URL,
} from '../constants/url.constants';
import { AuthService } from '../services/auth.service';
import { TitleService } from '../services/title.service';
import { VB_ROUTE } from '../../vb/constants/url.constants';
import { FS_ROUTE } from '../../fs/constants/url.constants';
import { VF_ROUTE } from '../../vf/constants/url.constant';
import { ROLES } from '../constants/role.constants';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  readonly loginUrl = LAUNCHER_LOGIN_URL;
  readonly launcherUrl = LAUNCHER_URL;
  readonly multidyneUrl = MULTIDYNE_HOME_URL;
  readonly vbRequirementUrl = VB_ROUTE.REQUIREMENT;
  readonly fsRequirementUrl = FS_ROUTE.REQUIREMENT;
  readonly vfRequirementUrl = VF_ROUTE.REQUIREMENT;

  constructor(public authService: AuthService) {}

  ngOnInit(): void {}

  logout() {
    this.authService.logout();
  }

  isAdmin() {
    return this.authService.hasAnyAuthority([ROLES.ADMIN]);
  }

  redirectToManageUsers() {
    window.location.href = LAUNCHER_MANAGE_USERS_URL;
  }
}
