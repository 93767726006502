import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinnerStatusService {
  // behaviour subject to check if spinner is active
  private spinnerSubject = new BehaviorSubject<boolean>(false);
  spinnerActive = this.spinnerSubject.asObservable();

  update(status: boolean) {
    this.spinnerSubject.next(status);
  }
}
