import { environment } from '../../../environments/environment';

export const MULTIDYNE_HOME_URL = 'https://www.multidyne.com/';
export const LAUNCHER_URL = environment.LAUNCHER_URL;
export const GET_JWT_URL = LAUNCHER_URL + 'auth/getAuthToken';
export const LAUNCHER_LOGIN_URL = LAUNCHER_URL + 'auth/login';
export const LAUNCHER_MANAGE_USERS_URL = LAUNCHER_URL + 'auth/manageUsers';
export const LAUNCHER_LOGOUT_URL = LAUNCHER_URL + 'auth/logout';
export const APP_BASE_ROUTE = '/';
export const APP_BASE_HREF = environment.APP_BASE_HREF;
