import { environment } from '../../../environments/environment';
import { APP_BASE_HREF, APP_BASE_ROUTE } from '../../shared/constants/url.constants';
import { CardCombinationCardCode } from './card.constant';
export const VB_ROUTE = {
  REQUIREMENT: APP_BASE_ROUTE + 'vb/',
};

export const VB_BASE_URL = environment.VB_BASE_URL;
export const GET_BOXES_URL = VB_BASE_URL + 'getVbBoxes';

//frontend image paths
const VB_IMG_BASE_PATH = APP_BASE_HREF + 'assets/img/vb/';
export const ConnectorImgPath = {
  ST_WT_POWER: VB_IMG_BASE_PATH + 'power-st.png',
  LC_WT_POWER: VB_IMG_BASE_PATH + 'power-lc.png',
  SC_WT_POWER: VB_IMG_BASE_PATH + 'power-sc.png',
  NEUTRIK_LOCAL_WET: VB_IMG_BASE_PATH + 'power-local-neutrik-duo-wet.png',
  SMPTE_PLUG_REMOTE_WET: VB_IMG_BASE_PATH + 'power-remote-smpte-plug-wet.png',
  SMPTE_SOCKET_REMOTE_WET: VB_IMG_BASE_PATH + 'power-remote-smpte-jack-wet.png',
  LOCAL_HYBRID_POWER: VB_IMG_BASE_PATH + 'local-hybrid-power.png',
};
export const EMPTY_SLOT_IMG = VB_IMG_BASE_PATH + 'empty-slot.png';

export const CardImgPath: Record<CardCombinationCardCode, string> = {
  [CardCombinationCardCode.VIDEO_3G]: VB_IMG_BASE_PATH + '3g.png',
  [CardCombinationCardCode.VIDEO_12G]: VB_IMG_BASE_PATH + '12g.png',
  [CardCombinationCardCode.VIDEO_HDMI]: VB_IMG_BASE_PATH + 'hdmi.png',
  [CardCombinationCardCode.COMPOSITE]: VB_IMG_BASE_PATH + 'composite.png',
  [CardCombinationCardCode.GENLOCK]: VB_IMG_BASE_PATH + 'sync.png',
  [CardCombinationCardCode.ETHERNET]: VB_IMG_BASE_PATH + 'ethernet.png',
  [CardCombinationCardCode.ETHERNET_POE]: VB_IMG_BASE_PATH + 'ethernet.png',
  [CardCombinationCardCode.Eth10G]: VB_IMG_BASE_PATH + 'ethernet-10g.png',
  [CardCombinationCardCode.Eth10GPoe]: VB_IMG_BASE_PATH + 'ethernet-10g.png',
  [CardCombinationCardCode.TIMECODE]: VB_IMG_BASE_PATH + 'tc-lanc.png',
  [CardCombinationCardCode.TIMECODE_LANC]: VB_IMG_BASE_PATH + 'tc-lanc.png',
  [CardCombinationCardCode.AUDIO]: VB_IMG_BASE_PATH + 'audio.png',
  [CardCombinationCardCode.AUDIO_AES]: VB_IMG_BASE_PATH + 'aes.png',
  [CardCombinationCardCode.DATA]: VB_IMG_BASE_PATH + 'data.png',
};
export const DATA_TALLY_IMG_PATH = VB_IMG_BASE_PATH + 'data-tally.png';
export const DTAP_IMG_PATH = VB_IMG_BASE_PATH + 'juice-dtap.png';
export const BARREL_IMG_PATH = VB_IMG_BASE_PATH + 'juice-2-55mm.png';
