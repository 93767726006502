import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GET_JWT_URL, LAUNCHER_LOGOUT_URL } from '../constants/url.constants';
import { Jwt } from '../models/jwt.model';
import { JWTTokenService } from './jwt-token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  roles?: string[];
  constructor(private jwtTokenService: JWTTokenService,
    private httpClient: HttpClient) { }

  isAuthenticated(): boolean {
    if (this.jwtTokenService.jwtToken && !this.jwtTokenService.isTokenExpired()) {
      return true;
    }
    return false;
  }

  hasAnyAuthority(authorities: string[] | string): boolean {
    if (!this.roles) {
      return false;
    }
    if (!Array.isArray(authorities)) {
      authorities = [authorities];
    }
    return this.roles.some((roleName) => authorities.includes(roleName));
  }

  checkForLogin(): void {
    this.getJwtToken().subscribe(
      (data) => {
        if (data.jwt !== null) {
          this.jwtTokenService.jwtToken = data.jwt;
          this.roles = this.jwtTokenService.getRoles();
        }
        else {
          this.jwtTokenService.clearToken();
        }
      }
    )
  }

  getJwtToken(): Observable<Jwt> {
    return this.httpClient.get<Jwt>(GET_JWT_URL);
  }

  logout(): void {
    this.jwtTokenService.clearToken();
    window.location.href = LAUNCHER_LOGOUT_URL;
  }
}
