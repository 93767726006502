import { environment } from '../../../environments/environment';
import { APP_BASE_ROUTE } from '../../shared/constants/url.constants';

export const FS_BASE_URL = '/fs-api/';
export const URL_IS_LOGGED_IN = FS_BASE_URL + 'profile/isLoggedIn';
export const GET_CONFIG_DETAILS_URL = FS_BASE_URL + 'requirement';
export const LAUNCHER_URL = environment.LAUNCHER_URL;
export const LOGIN_URL = LAUNCHER_URL + 'auth/login';
export const LOGOUT_URL = LAUNCHER_URL + 'auth/logout';

export const FS_ROUTE = {
  REQUIREMENT: APP_BASE_ROUTE + 'fiber-saver/',
};
