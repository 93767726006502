import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { SpinnerStatusService } from '../services/spinner-status.service';
import { BYPASS_SPINNER_INTERCEPTOR } from '../../fs/constants/app.constants';

/**
 * Adds a loading spinner during any http request in the application
 */
@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  /**
   * Outstanding requests count
   */
  count = 0;

  constructor(private spinner: NgxSpinnerService, private spinnerStatusService: SpinnerStatusService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.context.get(BYPASS_SPINNER_INTERCEPTOR)) {
      return next.handle(request);
    }
    this.spinner.show();
    this.spinnerStatusService.update(true);
    this.count++;
    return next.handle(request).pipe(
      finalize(() => {
        this.count--;
        if (this.count === 0) {
          this.spinner.hide();
          this.spinnerStatusService.update(false);
        }
      })
    );
  }
}
