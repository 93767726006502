import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JWTTokenService } from '../services/jwt-token.service';
import { VF_BASE_URL } from '../../vf/constants/url.constant';
import { FS_BASE_URL } from '../../fs/constants/url.constants';
import { VB_BASE_URL } from '../../vb/constants/url.constants';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private jwtService: JWTTokenService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (
      !(
        request.url.startsWith(VF_BASE_URL) ||
        request.url.startsWith(FS_BASE_URL) ||
        request.url.startsWith(VB_BASE_URL)
      )
    ) {
      return next.handle(request);
    }

    const token = this.jwtService.jwtToken;
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token,
        },
      });
    }
    return next.handle(request);
  }
}
