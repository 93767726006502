import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Injectable()
export class HttpErrorsInterceptor implements HttpInterceptor {
  snackBar: MatSnackBar;

  constructor(private injector: Injector, private router: Router) {
    this.snackBar = this.injector.get(MatSnackBar);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap({
        error: (err: HttpErrorResponse) => {
          console.log(err);
          if (err.status === 401) {
            //unauthorized access. load login
            this.showSnackbar('The requested data has restricted access.');
          } else if (err.status === 502 || err.status === 504) {
            //App server is down.
            this.showSnackbar('Unable to reach application. Please contact Admin.');
          }
          //err.error contains the data provided by server. Backend provides string for some API and JSON for some API. handle it first.
          else if (err.error?.message) {
            //Some requests are having message property
            this.showSnackbar(err.error?.message);
          } else if (typeof err.error == 'string') {
            //if the error is string, show it directly.
            this.showSnackbar(err.error);
          }
          //server didn't provide any error message. Show generic HTTP error code text
          else if (err.status === 0) {
            this.showSnackbar('Unable to connect. Please check your network connection');
          } else {
            this.showSnackbar(err?.statusText);
          }
        },
      })
    );
  }
  showSnackbar(msg: string): void {
    this.snackBar.open(msg, 'Dismiss', { duration: undefined });
  }
}
