export enum CardCode {
  dTx3G = 'dTx3G',
  Tx3G = 'Tx3G',
  dRx3G = 'dRx3G',
  Rx3G = 'Rx3G',
  TRx3G = 'TRx3G',
  dTx12G = 'dTx12G',
  Tx12G = 'Tx12G',
  dRx12G = 'dRx12G',
  Rx12G = 'Rx12G',
  TRx12G = 'TRx12G',
  TxHDMI = 'TxHDMI',
  RxHDMI = 'RxHDMI',
  TxComp = 'TxComp',
  RxComp = 'RxComp',
  TRxComp = 'TRxComp',
  Ethernet = 'ethernet',
  EthPoe = 'ethernet-poe',
  Eth10G = 'ethernet-10g',
  Eth10GPoe = 'ethernet-10g-poe',
  TxGenlock = 'TxGenlock',
  RxGenlock = 'RxGenlock',
  Timecode = 'timecode',
  TimecodeLanc = 'timecode-lanc', //not used
  AudioAnalog = 'audio-analog',
  AudioAES = 'audio-aes',
  Data = 'data',
  JuiceDtap = 'juice-dtap',
  JuiceBarrel = 'juice-barrel',
  JuicePoe = 'juice-poe',
  JuiceOnOtherSide = 'juice-on-other-side',
}

export enum CardCombinationCardCode {
  VIDEO_3G = 'video3G',
  VIDEO_12G = 'video12G',
  VIDEO_HDMI = 'videoHdmi',
  GENLOCK = 'genlock',
  TIMECODE = 'timecode',
  TIMECODE_LANC = 'timecodeLanc',
  AUDIO = 'audio',
  AUDIO_AES = 'audioAes',
  COMPOSITE = 'composite',
  DATA = 'data',
  ETHERNET = 'ethernet',
  ETHERNET_POE = 'ethernetPoe',
  Eth10G = 'ethernet10g',
  Eth10GPoe = 'ethernet10gPoe',
}
