import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { LocalStorageService } from 'ngx-webstorage';
import { JWT_STORAGE_VAR } from '../constants/app.constants';
import { JwtPayload } from '../models/jwt-payload.model';

@Injectable({
  providedIn: 'root'
})
export class JWTTokenService {

  private _jwtToken?: string | undefined;
  private _decodedToken!: JwtPayload;

  constructor(private localStorage: LocalStorageService) {
  }

  set jwtToken(token: string | undefined) {
    if (token) {
      this._jwtToken = token;
      this.localStorage.store(JWT_STORAGE_VAR, token);
      this.decodeToken();
    }
  }

  get jwtToken(): string | undefined {
    if (!this._jwtToken) {
      //case like page refresh/app reload: load it from storage.
      this._jwtToken = this.localStorage.retrieve(JWT_STORAGE_VAR);
      this.decodeToken();
    }
    return this._jwtToken;
  }

  clearToken() {
    this._jwtToken = undefined;
    this.localStorage.clear(JWT_STORAGE_VAR);
  }

  decodeToken() {
    if (this._jwtToken) {
      this._decodedToken = jwt_decode(this._jwtToken);
    }
  }

  getDecodeToken() {
    if (this._decodedToken)
      return this._decodedToken;
    throw new Error('Decode error: JWT is not defined');
  }


  getExpiryTime() {
    return this._decodedToken ? this._decodedToken.exp : null;
  }

  getRoles(): string[] {
    return this._decodedToken ? this._decodedToken.roles : [];
  }

  isTokenExpired(): boolean {
    const expiryTime = this.getExpiryTime();
    if (expiryTime) {
      return ((1000 * expiryTime) - (new Date()).getTime()) < 5000;
    } else {
      return false;
    }
  }
}