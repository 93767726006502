<mat-toolbar color="primary" class="mat-elevation-z6">
  <span class="navbar-brand d-flex align-items-center">
    <a [href]="multidyneUrl"><img src="assets/img/logo.png" class="d-inline-block align-top logo logo-img" alt="" /></a>
    <span class="ms-2 fw-semi-bold">Configurators</span>
  </span>
  <button class="ms-4" mat-button [routerLink]="vbRequirementUrl" routerLinkActive="active">
    <span class="fs-6">Versatile Brix Box</span>
  </button>
  <button class="ms-4" mat-button [routerLink]="fsRequirementUrl" routerLinkActive="active">
    <span class="fs-6">Fiber Saver</span>
  </button>
  <button class="ms-4" mat-button [routerLink]="vfRequirementUrl" routerLinkActive="active">
    <span class="fs-6">VF-9000</span>
  </button>
  <button *ngIf="isAdmin()" class="ms-4" mat-button (click)="redirectToManageUsers()" routerLinkActive="active">
    <span class="fs-6">Manage Users</span>
  </button>
  <div class="col d-flex justify-content-end">
    <button *ngIf="authService.isAuthenticated()" mat-button>
      <a class="text-decoration-none text-white" (click)="logout()"> Logout </a>
    </button>
    <button *ngIf="!authService.isAuthenticated()" mat-button>
      <a class="text-decoration-none text-white" [href]="loginUrl"> Login </a>
    </button>
  </div>
</mat-toolbar>
